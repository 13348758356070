// Here you can add other styles
.customIcon {
  fill: white;
  width: 14px;
  height: 14px;
}

.customRupeeIcon {
  fill: rgb(60, 75, 100);
  width: 14px;
  height: 10px;
}

.loader-container {
  position: fixed !important;
  z-index: 1090;
  // height: 100%;
  // width: 100%;
  top: 50%;
  left: 50%;
}

.page-loader {
  width: 4rem;
  height: 4rem;
  // margin-left: 32%;
  // margin-top: 10%;
  // z-index:1060;
}

.remove-index {
  z-index: -1;
}

.card.active {
  box-shadow: 0px 0px 5px 0px grey;
}

.card-body-custom-height {
  height: 100px !important;
}

.customPlusIcon {
  fill: white;
  width: 18px;
  height: 18px;
}

.addWalletBalancePlusIcon {
  fill: #1969e9;
  width: 18px;
  height: 18px;
}

.plusButtonOutline:hover .addWalletBalancePlusIcon {
  fill: white;
  width: 18px;
  height: 18px;
}

.customPlusIconBlack {
  fill: rgb(0, 0, 0);
  width: 18px;
  height: 18px;
}

.toDoCheckIcon {
  height: 30px;
  width: 30px;
}

.toDoCheckIcon:hover {
  cursor: pointer;
}

.customBookPatientIcon {
  fill: rgb(255, 255, 255);
  width: 20px;
  height: 20px;
}

.customMinusIcon {
  fill: black;
  width: 22px;
  height: 22px;
}

.leave-icons {
  width: 40px;
  height: 40px;
  fill-opacity: 0.6;
}

.action-icons {
  width: 20px;
  height: 20px;
  fill-opacity: 0.6;
}

.customCalendarIcon {
  // fill: black;
  width: 30px;
  height: 20px;
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.toaster-top-full,
.toaster-top-center,
.toaster-top-right,
.toaster-top-left,
.toaster-bottom-full,
.toaster-bottom-center,
.toaster-bottom-right,
.toaster-bottom-left {
  width: 40% !important;
}

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.bg-cover {
  margin: 0;
  padding: 0;
  // background: url("./../assets/img/login_bg.jpeg") !important;
  background-size: cover;
  background-color: transparent;
  transition: ease-out;
  background-image: linear-gradient(rgb(0, 119, 255), rgb(0, 255, 76));
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  // top: 0;
  // left: 0;
  // bottom:0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
  opacity: 0.9;
}

.box {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.53);
  border-radius: 0.625rem;
  border-color: #27262300 !important;
}

.login-header {
  color: #fff;
}

// .employee-landing-bg{
//   background: url("../assets/img/bg_profile.png");
//   width: inherit;
// }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.todoCard {
  overflow: hidden;
}

.todoCard:hover {
  overflow-y: auto;
  overflow-y: overlay;
}

input[type="number"] {
  -moz-appearance: textfield;
  
}

// .bg-profile-cover{
//   background: url("../assets/img/bg_profile.png");
//   // width: 124px;
//   height: 124px;
//   display: -webkit-box;
//   display: flex;
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 1.5em;
//   border-radius: 100%;
//   box-shadow: 0 13px 26px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.2);
// }

.employee-landing-bg {
  background: url("../assets/img/bg_l.jpg") center bottom no-repeat #eefdd8;
  border-bottom: 1px #000000 solid;
  // background-size: cover;
  background-position: left;
}

#edit-profile-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: grey;
  font-size: 25px;
  color: #fff;
  text-align: center;
  line-height: 120px;
  margin: auto;
}

.edit-icon {
  width: 200px;
  height: 200px;
  // color: rgb(191, 18, 18);
  position: absolute;
  margin-left: -30px;
  border: 2px solid grey;
  border-radius: 50%;

  // top: 20px;
  // right: -7px;
  // /* border: 1px solid; */
  // border-radius: 50%;
  // /* padding: 11px; */
  // height: 30px;
  // width: 30px;
  // display: flex !important;
  // align-items: center;
  // justify-content: center;
  // background-color: white;
  // color: cornflowerblue;
  // box-shadow: 0 0 8px 3px #B8B8B8;
}

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}

tr.strikeout td:after {
  content: "\00B7";
  font-size: 1px;
}
